.Anime-Slide-enter {
  transform: translateX(-50%);
  opacity: 0;
}
.Anime-Slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms ease-in-out;
}
.Anime-Slide-exit-active {
  transform: translateX(-50%);
  opacity: 0;
  transition: all 200ms ease-in-out;
}