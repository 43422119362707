.Button {
  @apply py-2;
  @apply px-2;
  @apply font-semibold;
  @apply rounded-lg;
  @apply shadow-md;
  @apply focus:outline-none;
  @apply disabled:bg-gray-300;
  @apply text-center;
}

.Button-Cyan {
  @apply bg-cyan-500;
  @apply hover:bg-cyan-600;
  @apply text-white;
}

.Button-Red {
  @apply bg-red-500;
  @apply hover:bg-red-600;
  @apply text-white;
}

.Button-Disabled {
  @apply bg-gray-400;
}