@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "animation";
@import "button";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.Page {
  @apply min-h-screen;
  @apply bg-gray-100;
  @apply py-4;
  @apply px-3;
}